<template>
  <div class="min-h-screen bg-hearts bg-gradient-to-b from-pink-50/80 to-white/90">
    <!-- 顶部导航栏 -->
    <header class="fixed top-0 left-0 right-0 bg-opacity-75 z-50 backdrop-blur-md">
      <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <!-- 标题 -->
          <h1 class="text-xl font-semibold text-gray-900 tracking-tight">
            {{ t('home.title') }}
          </h1>

          <div class="flex items-center space-x-4">
            <!-- 订阅升级按钮或创建好友按钮 -->
            <button  @click="goToCreateFriend"
              class="bg-gradient-to-r from-green-500 to-teal-500 px-3 py-1 rounded-lg shadow-xl">
              <span class="bg-clip-text text-transparent bg-gradient-to-r from-green-200 to-teal-200 font-bold text-sm">
                {{ t('home.createFriend') }}
              </span>
            </button>
            <!-- 用户头像或登录按钮 -->
            <div class="ml-4">
              <div v-if="user">
                <img :src="user.avatar" alt="User Avatar" :class="[
                  'w-8 h-8 rounded-full cursor-pointer ring-2',
                  is_member ? 'ring-amber-400 hover:ring-amber-500' : 'ring-gray-300 hover:ring-pink-500'
                ]" @click="goToUserPage" />
              </div>
              <UserCircleIcon class="w-8 h-8 text-gray-400 flex-shrink-0" v-else @click="openLoginModal" />
            </div>
          </div>

        </div>
      </div>
    </header>

    <!-- 主内容区 -->
    <main class="pt-16 pb-24 h-screen overflow-y-auto custom-scrollbar max-w-4xl mx-auto px-4">
      <!-- 朋友列表 -->
      <div class="grid gap-4" v-if="friends.length > 0">
        <div v-for="friend in friends" :key="friend.friend_id" @click="goToChat(friend.friend_id)"
          class="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden cursor-pointer">
          <div class="p-4 sm:p-6">
            <div class="flex items-center space-x-4">
              <!-- 头像容器 -->
              <div class="relative flex-shrink-0">
                <img :src="friend.avatar_url" :alt="friend.name"
                  class="w-14 h-14 sm:w-16 sm:h-16 rounded-full object-cover ring-2 ring-gray-200" />
                <div class="absolute -bottom-1 -right-1 w-4 h-4 bg-green-500 rounded-full ring-2 ring-white"></div>
              </div>

              <!-- 信息区域 -->
              <div class="flex-1 min-w-0">
                <h2 class="text-lg font-semibold text-gray-900 truncate">
                  {{ friend.name }}
                </h2>
                <p class="mt-1 text-gray-600 text-sm line-clamp-2">
                  {{ friend.bio }}
                </p>
              </div>

              <!-- 右侧箭头 -->
              <ChevronRightIcon class="w-5 h-5 text-gray-400 flex-shrink-0" />
            </div>
          </div>
        </div>
      </div>

      <div v-else class="flex justify-center items-center py-20">
        <svg class="animate-spin h-8 w-8 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
        </svg>
      </div>

      <!-- 分割线 -->
      <div class="flex justify-center items-center py-4 space-x-3 " v-if="myFriends.length > 0">
        <div class="flex-grow border-t border-gray-200"></div>
        <div class="text-center italic text-gray-400 text-sm">{{ t('home.myFriends') }}</div>
        <div class="flex-grow border-t border-gray-200"></div>
      </div>

      <!-- 我的好友列表 -->
      <div class="grid gap-4" v-if="myFriends.length > 0">
        <div v-for="friend in myFriends" :key="friend.friend_id" @click="goToChat(friend.friend_id)"
          class="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden cursor-pointer">
          <div class="p-4 sm:p-6">
            <div class="flex items-center space-x-4">
              <!-- 头像容器 -->
              <div class="relative flex-shrink-0">
                <img :src="friend.avatar_url" :alt="friend.name"
                  class="w-14 h-14 sm:w-16 sm:h-16 rounded-full object-cover ring-2 ring-gray-200" />
                <div class="absolute -bottom-1 -right-1 w-4 h-4 bg-green-500 rounded-full ring-2 ring-white"></div>
              </div>

              <!-- 信息区域 -->
              <div class="flex-1 min-w-0">
                <h2 class="text-lg font-semibold text-gray-900 truncate">
                  {{ friend.name }}
                </h2>
                <p class="mt-1 text-gray-600 text-sm line-clamp-2">
                  {{ friend.bio }}
                </p>
              </div>

              <!-- 右侧箭头 -->
              <ChevronRightIcon class="w-5 h-5 text-gray-400 flex-shrink-0" />
            </div>
          </div>
        </div>
      </div>

      <!-- 底部链接和信息 -->
      <div class="flex justify-center items-center mt-6">
        <a href="https://discord.gg/YQ4ABu5JCR" target="_blank" class="hover:underline">
          <img src="../assets/svg/discord.svg" class="w-5">
        </a>
      </div>

      <div class="text-center text-xs text-gray-400 mt-4">
        v1.0.0
      </div>

      <div class="text-center text-xs text-gray-400 mt-2">
        {{ t('home.support') }}：contact@chatnext.ai
      </div>

    </main>

    <!-- 订阅弹窗 -->
    <!-- <SubscriptionModal :isOpen="isSubscriptionModalOpen" @close="closeSubscriptionModal" /> -->


    <!-- 登录弹窗 -->
    <LoginModal :isOpen="isLoginModalOpen" @close="closeLoginModal" />
    
    <!-- 付款弹窗 -->
    <ChargeModal :isOpen="isChargeModalOpen" @close="closeChargeModal" />

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ChevronRightIcon, UserCircleIcon } from '@heroicons/vue/24/outline';
import { region } from '../hooks/region';
import LoginModal from '../components/LoginModal.vue'; // 引入 LoginModal 组件  
// import SubscriptionModal from '../components/SubscriptionModal.vue'; // 引入 SubscriptionModal 组件  
import ChargeModal from '../components/ChargeModal.vue'; // 引入 SubscriptionModal 组件  

import { getCookie, record, gtag } from '../utils'

// 获取语言相关方法  
const { t, currentRegion } = region();

// 路由实例  
const router = useRouter();

// 状态变量  
const friends = ref([]);
const myFriends = ref([]);
const isLoginModalOpen = ref(false);
// const isSubscriptionModalOpen = ref(false);
const isChargeModalOpen = ref(false);

const is_member = ref(false);
const user = ref(null);
const errorMessage = ref(''); // 用于显示错误信息

// 获取朋友列表  
const getFriendsList = async () => {
  try {
    const region = currentRegion.value;
    const response = await fetch(`/api/friend/list?region=${region}`);
    if (!response.ok) {
      throw new Error('获取好友列表失败');
    }
    const data = await response.json();
    friends.value = data;
  } catch (err) {
    console.error('获取好友列表出错:', err);
    errorMessage.value = '获取好友列表出错';
  }
};

// 获取我的好友列表  
const getMyFriends = async () => {
  try {
    const token = getCookie('chatnext_token');
    if (!token) {
      return;
    }

    const response = await fetch(`/api/friend/my`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 404) {
        myFriends.value = [];
      } else {
        throw new Error('获取我的好友列表失败');
      }
    } else {
      const data = await response.json();
      myFriends.value = data;
    }
  } catch (err) {
    console.error('获取我的好友列表出错:', err);
    errorMessage.value = '获取我的好友列表出错';
  }
};

// 检查登录状态并获取用户信息  
const checkLogin = async () => {

  const token = getCookie('chatnext_token'); // 从 Cookie 中读取 token  

  if (!token) {
    return;
  }

  try {
    const response = await fetch('/api/user/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      let result = await response.json();
      user.value = result;
      is_member.value = result.is_member;
    } else {
      console.error('获取用户信息失败');
    }
  } catch (error) {
    console.error('检查登录状态出错:', error);
  }

};

// 跳转到聊天页面  
const goToChat = (friendId) => {

  gtag('event', 'conversion', {
    'send_to': 'AW-11364447192/FOIhCM6Gp_MZENjn_qoq'
  });

  router.push({
    name: 'Chat',
    params: { friend: friendId },
  });
};

// // 打开订阅弹窗  
// const openSubscriptionModal = () => {
//   isSubscriptionModalOpen.value = true;

//   record({
//     name: 'rumeng_homepage_upgrade',
//     attributes: {
//       // method: 'anonymous',
//     },
//   });

// };

// // 关闭订阅弹窗  
// const closeSubscriptionModal = () => {
//   isSubscriptionModalOpen.value = false;
// };


// 打开订阅弹窗  
const openChargeModal = () => {
  isChargeModalOpen.value = true;

  // record({
  //   name: 'rumeng_homepage_upgrade',
  //   attributes: {
  //     // method: 'anonymous',
  //   },
  // });

};

// 关闭订阅弹窗  
const closeChargeModal = () => {
  isChargeModalOpen.value = false;
};

// 打开登录弹窗  
const openLoginModal = () => {
  isLoginModalOpen.value = true;
};

// 关闭登录弹窗  
const closeLoginModal = () => {
  isLoginModalOpen.value = false;

};

// 跳转到用户页面  
const goToUserPage = () => {
  router.push({ name: 'User' });
};

// 跳转到创建好友页面  
const goToCreateFriend = () => {
  router.push({ name: 'Create' });
};

// 生命周期钩子  
onMounted(async () => {
  document.title = t('home.title');
  await checkLogin(); // 检查登录状态  
  await getFriendsList(); // 获取好友列表  
  await getMyFriends(); // 获取我的好友列表
});
</script>

<style scoped>
/* 可以根据需要添加样式 */
</style>