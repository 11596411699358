<template>
    <div v-if="isOpen" class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
            <h2 class="text-lg font-semibold text-gray-800 mb-4 text-center">
                {{ t('login.title') }}  
            </h2>
            <p class="text-gray-600 mb-4">
                {{ t('login.description') }}
            </p>
            
            <!-- Google Login Button -->
            <button @click="googleLogin"
                class="flex items-center justify-center bg-black text-white px-4 py-2.5 rounded-lg w-full mb-3">
                <img src="../assets/svg/google.svg" class="mr-2 w-6 h-6" alt="Google">
                {{ t('login.googleButton') }}
            </button>

            <!-- Custom Telegram Button -->
            <!-- <button @click="handleTelegramLogin"
                class="flex items-center justify-center bg-black text-white px-4 py-2.5 rounded-lg w-full mb-3">
                <img src="../assets/svg/telegram.svg" class="mr-2 w-6 h-6" alt="Telegram">
                {{ t('login.telegramButton') }}
            </button> -->

            <!-- Hidden Original Telegram Button -->
            <!-- <div id="telegram-login-button" class="hidden"></div> -->

            <button @click="handleCancel" class="text-gray-500 hover:text-gray-700 text-sm w-full mt-2">
                {{ t('login.cancel') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { region } from '../hooks/region';
import { record } from '../utils';

const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(['close']);
const { t } = region();

// Google登录处理
const googleLogin = () => {
    record({
        name: 'rumeng_login',
        attributes: {
            method: 'google',
            from: localStorage.getItem('from')
        },
    });

    const params = new URLSearchParams({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_GOOGLE_REDIRECT_URI,
        response_type: 'code',
        scope: 'email profile',
    });

    const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;
    window.location.href = googleLoginUrl;
};

// Telegram登录处理
const telegramBotName = process.env.VUE_APP_TELEGRAM_BOT_NAME;

const handleTelegramLogin = () => {
    record({
        name: 'rumeng_login',
        attributes: {
            method: 'telegram',
            from: localStorage.getItem('from')
        },
    });

    // 触发原始Telegram按钮点击
    const telegramButton = document.querySelector('iframe[name="telegram-login-button"]')
        ?.contentWindow.document.querySelector('button');
    if (telegramButton) {
        telegramButton.click();
    }
};
const loadTelegramWidget = () => {

// console.log(process.env.VUE_APP_TELEGRAM_REDIRECT_URI)

// 添加短暂延时确保 DOM 已经渲染
setTimeout(() => {
    const container = document.getElementById('telegram-login-button');
    if (!container) {
        console.error("Telegram login button container not found");
        return;
    }

    // 清除现有的 Telegram 登录脚本（如果存在）
    const existingScript = document.getElementById('telegram-login-script');
    if (existingScript) {
        existingScript.remove();
    }

    const script = document.createElement('script');
    script.id = 'telegram-login-script';
    script.src = "https://telegram.org/js/telegram-widget.js?22";
    script.async = true;
    script.setAttribute("data-telegram-login", telegramBotName);
    script.setAttribute("data-size", "large");
    // script.setAttribute("data-radius", "8");
    script.setAttribute("data-userpic", "false");
    script.setAttribute("data-auth-url", process.env.VUE_APP_TELEGRAM_REDIRECT_URI);
    script.setAttribute("data-request-access", "write");
    container.appendChild(script);
}, 100);
};

// 监听isOpen变化，加载Telegram小部件
watch(() => props.isOpen, (newValue) => {
    if (newValue) {
        loadTelegramWidget();
    }
});

// 组件挂载时，如果对话框已打开，加载小部件
onMounted(() => {
    if (props.isOpen) {
        loadTelegramWidget();
    }
});

const handleCancel = () => {
    emit('close');
};
</script>

<style scoped>
.hidden {
    display: none !important;
}
</style>