<template>
  <div class="min-h-screen bg-hearts bg-gradient-to-b from-pink-50/80 to-white/90">
    <!-- 顶部导航栏 -->
    <header class="fixed top-0 left-0 right-0 bg-opacity-75 z-50 transition-all duration-300 backdrop-blur-md">
      <div class="max-w-4xl mx-auto">
        <div class="flex items-center justify-between h-14 px-4">
          <!-- 左侧返回按钮 -->
          <button @click="goBack"
            class="text-gray-700 hover:text-gray-900 rounded-full hover:bg-gray-100 transition-colors">
            <ChevronLeftIcon class="h-5 w-5" />
          </button>
          <!-- 中间标题 -->
          <h2 class="font-semibold text-gray-900">{{ t('user.title') }}</h2>
          <!-- 右侧占位 -->
          <div class="w-5"></div>
        </div>
      </div>
    </header>

    <!-- 主内容区 -->
    <main
      class="pt-16 pb-24 h-screen overflow-y-auto custom-scrollbar max-w-4xl mx-auto px-4 flex flex-col items-center">
      <div v-if="user" class="bg-white rounded-xl shadow p-6 w-full max-w-md">
        <div class="flex flex-col items-center">
          <img :src="user.avatar" alt="User Avatar" class="w-24 h-24 rounded-full object-cover ring-2 ring-gray-200"
            loading="lazy" />
          <h2 class="mt-4 text-2xl font-semibold text-gray-900">{{ user.username }}</h2>
        </div>

        <div class="mt-2 w-full text-center text-gray-700">
          剩余积分：{{ user.points }}
        </div>

        <!-- 充值按钮组 -->
        <div class="mt-6 w-full">
          <button @click="openChargeModal"
            class="bg-gradient-to-r from-gray-800 to-black px-4 py-2 rounded-lg shadow-xl w-full flex items-center justify-center">
            <div class="flex bg-clip-text text-transparent bg-gradient-to-r from-orange-300 to-purple-300 font-bold">
              {{ t('user.chargeButton') }}
            </div>
          </button>

          <button @click="logout"
            class="mt-4 w-full bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-lg shadow">
            {{ t('user.logout') }}
          </button>
        </div>
      </div>

      <div v-else class="flex justify-center items-center py-20">
        <svg class="animate-spin h-8 w-8 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
        </svg>
      </div>

      <div v-if="userId" class="text-center text-xs text-gray-400 mt-2">
        localUID：{{ userId }}
      </div>

    </main>

    <!-- 充值弹窗 -->
    <ChargeModal :isOpen="isChargeModalOpen" @close="closeChargeModal" />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import { region } from '../hooks/region';
import { getCookie, record } from '../utils';
import ChargeModal from '../components/ChargeModal.vue'; // 引入 ChargeModal 组件  

// 获取语言相关方法  
const { t } = region();

// 路由实例  
const router = useRouter();

// 状态变量  
const user = ref(null);
const isChargeModalOpen = ref(false);

//读取旧的本地id
const userId = ref(null);

// 检查登录状态并获取用户信息  
const checkLogin = async () => {
  const token = getCookie('chatnext_token'); // 从 Cookie 中读取 token  

  if (!token) {
    console.log('未找到 token');
    router.push({ name: 'Login' }); // 如果没有 token，重定向到登录页  
    return;
  }

  try {
    const response = await fetch('/api/user/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      let result = await response.json();
      user.value = result;
    } else if (response.status === 401) {
      // Token 无效或过期，执行注销  
      logout();
    } else {
      console.error('获取用户信息失败');
    }
  } catch (error) {
    console.error('检查登录状态出错:', error);
  }
};

// 注销功能  
const logout = () => {
  document.cookie = 'chatnext_token=; Max-Age=0; Path=/; Domain=chatnext.ai;';
  router.push({ name: 'Home' }); // 假设主页的路由名称为 'Home'  
};

// 返回上一页  
const goBack = () => {
  router.back();
};

// 打开充值弹窗  
const openChargeModal = () => {
  isChargeModalOpen.value = true;

  record({
    name: 'rumeng_userpage_charge',
    attributes: {
      // method: 'anonymous',
    },
  });
};

// 关闭充值弹窗  
const closeChargeModal = () => {
  isChargeModalOpen.value = false;
};

// 生命周期钩子  
onMounted(async () => {
  document.title = t('user.title');
  userId.value = localStorage.getItem('user_id');
  await checkLogin(); // 检查登录状态  
});
</script>

<style scoped>
/* 添加平滑滚动效果 */
html {
  scroll-behavior: smooth;
}

/* 优化移动端适配 */
@media (max-width: 640px) {
  main {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* 自定义滚动条 */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

/* Spinner 动画 */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
