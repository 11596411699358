<!-- src/views/Chat.vue -->
<template>
  <div class="min-h-screen bg-hearts bg-gradient-to-b from-pink-50/80 to-white/90">
    <!-- 固定头部导航栏 -->
    <header class="fixed top-0 left-0 right-0 bg-opacity-75 z-50 transition-all duration-300 backdrop-blur-md">
      <div class="max-w-4xl mx-auto">
        <div class="flex items-center justify-between h-14 px-4">
          <button @click="goBack"
            class="text-gray-700 hover:text-gray-900 rounded-full hover:bg-gray-100 transition-colors">
            <ChevronLeftIcon class="h-5 w-5" />
          </button>

          <div class="flex items-center space-x-3">
            <img v-if="isScrolled" :src="friendInfo.avatar_url" :alt="friendInfo.name"
              class="w-8 h-8 rounded-full object-cover ring-2 ring-gray-200">
            <span class="font-semibold text-gray-900">{{ friendInfo.name }}</span>
          </div>
          <button @click="goToInfo(friendInfo.friend_id)"
            class="text-gray-700 hover:text-gray-900 rounded-full hover:bg-gray-100 transition-colors">
            <InformationCircleIcon class="h-5 w-5" />
          </button>
        </div>
      </div>
    </header>

    <!-- 主聊天区域 -->
    <main class="pt-14 pb-24 h-screen overflow-y-auto custom-scrollbar" ref="mainContainer" @scroll="handleScroll">
      <div class="max-w-4xl mx-auto px-4">
        <!-- 用户信息卡片 -->
        <div class="py-8">
          <div class="flex flex-col items-center space-y-4">
            <div class="relative inline-block profile-image">
              <div class="relative">
                <img :src="friendInfo.avatar_url" :alt="friendInfo.name"
                  class="w-24 h-24 rounded-full object-cover ring-4 ring-gray-50 shadow-sm">
                <div class="absolute -bottom-1 -right-1 w-6 h-6 bg-green-400 rounded-full ring-2 ring-white"></div>
              </div>
            </div>
            <div class="text-center">
              <h1 class="text-xl font-semibold text-gray-900">{{ friendInfo.name }}</h1>
              <div
                class="text-sm mt-2 inline-block font-medium bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 bg-clip-text text-transparent hover:opacity-80 transition-opacity">
                {{ friendInfo.bio }}
              </div>
            </div>
          </div>
        </div>

        <!-- 历史消息分隔线 -->
        <div v-if="messagesHistory" class="flex items-center space-x-3 my-6">
          <div class="flex-grow border-t border-gray-200"></div>
          <div class="text-center italic text-gray-400 text-sm">{{ t('chat.recent_messages') }}</div>
          <div class="flex-grow border-t border-gray-200"></div>
        </div>

        <!-- 消息列表 -->
        <div class="space-y-4 mb-28">
          <template v-for="(message, index) in messages" :key="index">
            <!-- 用户消息 -->
            <div v-if="message.role === 'user' && hasTextContent(message.content)"
              class="flex justify-end message-bubble-in">
              <div class="max-w-[80%] bg-pink-500 text-white rounded-2xl rounded-tr-sm px-4 py-2 shadow-sm">
                <div v-for="(contentBlock, idx) in message.content" :key="idx">
                  <span v-if="contentBlock.type === 'text'" class="break-words">
                    {{ contentBlock.text }}
                  </span>
                </div>
              </div>
            </div>

            <!-- AI回复消息 -->
            <div v-else-if="message.role === 'assistant' && hasContent(message.content)"
              class="flex justify-start message-bubble-in">
              <div class="bg-white rounded-2xl rounded-tl-sm py-2 px-4 shadow-md max-w-[80%] border border-pink-100">
                <div v-for="(contentBlock, idx) in message.content" :key="idx">
                  <div v-if="contentBlock.type === 'text'" v-html="renderMarkdown(contentBlock.text)"
                    class="text-gray-800"></div>
                </div>
              </div>
            </div>

            <!-- 兼容Think消息 -->
            <div v-else-if="message.role === 'think' && message.content.length > 0"
              class="flex flex-col justify-start mb-4 message-bubble-in">
              <div class="bg-white rounded-2xl rounded-tl-sm py-2 px-4 shadow-md max-w-[80%] border border-pink-100">
                <div class="text-sm text-pink-400 flex items-center italic">
                  💭 "{{ message.content[0].text }}"
                </div>
              </div>
            </div>

            <!-- Event消息 -->
            <div v-else-if="message.role === 'event' && hasContent(message.content)"
              class="flex justify-start message-bubble-in">
              <div class="bg-white rounded-2xl rounded-tl-sm py-2 px-4 shadow-md max-w-[80%] border border-pink-100">
                <div v-for="(contentBlock, idx) in message.content" :key="idx">
                  <!-- 根据event类型渲染不同的内容 -->
                  <div v-if="contentBlock.type === 'think'" class="text-sm text-pink-400 flex items-center italic">
                    💭 "{{ contentBlock.text }}"
                  </div>
                  <div v-if="contentBlock.type === 'image'" class="text-sm text-pink-400 flex items-center italic">
                    💭 "{{ contentBlock.text }}"
                  </div>

                  <!-- 思考内容 - 保持原样 -->
                  <div v-if="contentBlock.type === 'reflection'" class="text-sm text-pink-400 flex items-center italic">
                    💭 "{{ contentBlock.text }}"
                  </div>

                  <!-- 增加记忆 - 只优化标签样式 -->
                  <div v-else-if="contentBlock.type === 'addMemory'" class="relative rounded-lg mt-3">
                    <span class="absolute -top-8 left-0 px-2.5 py-0.5 text-xs font-medium 
                      bg-yellow-100 text-yellow-800 border border-yellow-200 
                      rounded-full shadow-sm">
                      {{ t('chat.add_memory') }}
                    </span>
                    <div class="flex items-center text-sm text-pink-400 italic">
                      🧠 "{{ contentBlock.text }}"
                    </div>
                  </div>

                  <!-- 编辑记忆 - 只优化标签样式 -->
                  <div v-else-if="contentBlock.type === 'modifyMemory'" class="relative rounded-lg mt-3">
                    <span class="absolute -top-8 left-0 px-2.5 py-0.5 text-xs font-medium 
                      bg-yellow-100 text-yellow-800 border border-yellow-200 
                      rounded-full shadow-sm">
                      {{ t('chat.memories_edit_title') }}
                    </span>
                    <div class="flex items-center text-sm text-pink-400 italic">
                      🧠 "{{ contentBlock.text }}"
                    </div>
                  </div>

                  <!-- 移除记忆 - 只优化标签样式 -->
                  <div v-else-if="contentBlock.type === 'eraseMemory'" class="relative rounded-lg mt-3">
                    <span class="absolute -top-8 left-0 px-2.5 py-0.5 text-xs font-medium 
                      bg-yellow-100 text-yellow-800 border border-yellow-200 
                      rounded-full shadow-sm">
                      {{ t('chat.delete_memory') }}
                    </span>
                    <div class="flex items-center text-sm text-pink-400 italic">
                      🧠 "{{ contentBlock.text }}"
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!-- 充值消息 -->
            <div v-else-if="message.role === 'capitalist' && hasContent(message.content)"
              class="flex justify-start message-bubble-in">
              <div class="bg-white rounded-2xl rounded-tl-sm p-2 shadow-md max-w-[80%] border border-pink-100">
                <div class="bg-red-50 text-gray-600 text-sm px-4 py-2 rounded-lg w-full flex flex-col">
                  <span>{{ t('chat.seek_profit') }}</span>
                </div>
                <button @click="openChargeModal" v-if="!is_member"
                  class="bg-gradient-to-r from-gray-800 to-black px-3 py-1.5 rounded-lg shadow-xl w-full mt-2">
                  <span
                    class="bg-clip-text text-transparent bg-gradient-to-r from-orange-300 to-purple-300 font-bold text-sm">
                    {{ t('chat.chargeButton') }}
                  </span>
                </button>
              </div>
            </div>

            <!-- 清除对话消息 -->
            <div v-else-if="message.role === 'clear' && hasContent(message.content)"
              class="flex justify-center items-center py-4 space-x-3">
              <div class="flex-grow border-t border-gray-200"></div>
              <div class="text-center italic text-gray-400 text-sm">{{ t('chat.new_conversation') }}</div>
              <div class="flex-grow border-t border-gray-200"></div>
            </div>

            <!-- 错误消息 -->
            <div v-else-if="message.role === 'error' && hasContent(message.content)"
              class="flex justify-center items-center py-4">
              <div class="bg-red-50 text-red-500 text-sm flex items-center space-x-1.5 px-4 py-2 rounded-lg">
                <InformationCircleIcon class="h-4 w-4" />
                <span>{{ t('error_message') }}</span>
              </div>
            </div>

          </template>
        </div>
      </div>
    </main>

    <!-- 底部输入区域 -->
    <div class="fixed bottom-0 left-0 right-0 bg-opacity-75 backdrop-blur-md">
      <div class="max-w-4xl mx-auto p-3 py-4">
        <!-- 输入区域 -->
        <div class="flex items-center">
          <!-- 清除按钮 -->
          <button @click="newChat"
            class="flex items-center justify-center text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100 transition-colors">
            <img src="../assets/svg/clear.svg" :alt="t('chat.clear_button')" class="w-6 h-6">
          </button>

          <!-- 输入框和发送按钮 -->
          <div class="flex-1 bg-gray-50 rounded-2xl px-4 pr-0 ml-2">
            <div class="flex items-center">
              <textarea v-model="newMessage" rows="1"
                :placeholder="thinking ? t('chat.thinking_placeholder') : t('chat.input_placeholder')"
                @input="autoResize" @keydown="handleKeydown" ref="messageInput"
                class="flex-1 bg-transparent border-none resize-none focus:outline-none py-2 my-0.5 min-h-[36px] max-h-[160px] custom-scrollbar leading-6">
          </textarea>

              <!-- 发送按钮 -->
              <button @click="sendMessage" :class="['h-10 w-10 flex items-center justify-center rounded-full transition-colors',
                !newMessage.value
                  ? 'text-gray-400 cursor-not-allowed'
                  : 'text-blue-500 hover:text-blue-600']">
                <PaperAirplaneIcon class="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 充值弹窗 -->
    <ChargeModal :isOpen="isChargeModalOpen" @close="closeChargeModal" />

    <!-- 登录弹窗 -->
    <LoginModal :isOpen="isLoginModalOpen" @close="closeLoginModal" />
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { marked } from 'marked'
import {
  ChevronLeftIcon,
  InformationCircleIcon,
  PaperAirplaneIcon,
} from '@heroicons/vue/24/outline'
import { region } from '../hooks/region' // 使用您自定义的 region 钩子

import LoginModal from '../components/LoginModal.vue' // 引入 LoginModal 组件  
import ChargeModal from '../components/ChargeModal.vue' // 引入 ChargeModal 组件  

import { getCookie, record } from '../utils'

// 路由
const router = useRouter()
const route = useRoute();

// 语言相关
const { t } = region()

const isLoginModalOpen = ref(false);
const isChargeModalOpen = ref(false);

// 状态定义
const isScrolled = ref(false)

const messages = ref([])
const messagesHistory = ref(false)
const newMessage = ref('')
const thinking = ref(false)
const selectedImage = ref(null)
const mainContainer = ref(null)
const messageInput = ref(null)

// 好友信息
const friendInfo = ref({
  friend_id: '',
  name: '',
  avatar_url: '',
  bio: '',
  group_link: ''
})

// 打开充值弹窗  
const openChargeModal = () => {
  isChargeModalOpen.value = true;
  record({
    name: 'message_limit_upgrade',
    attributes: {
      // method: 'anonymous',
    },
  });
};

// 关闭充值弹窗  
const closeChargeModal = () => {
  isChargeModalOpen.value = false;
};

// 打开登录弹窗  
const openLoginModal = () => {
  isLoginModalOpen.value = true;
};

// 关闭登录弹窗  
const closeLoginModal = () => {
  isLoginModalOpen.value = false;
};

// 初始化方法
const init = async () => {
  getfriendInfo()
  const token = getCookie('chatnext_token'); // 从 Cookie 中读取 token
  if (!token) return
  messagesInit()
}

// 获取好友信息
const getfriendInfo = async () => {
  const friend = route.params.friend;
  if (!friend) {
    console.error('friend parameter is missing')
    return
  }
  try {
    const response = await fetch(`/api/friend/get?friend_id=${friend}`)
    if (response.ok) {
      const data = await response.json()
      friendInfo.value = data
      // 更新页面标题
      document.title = `${friendInfo.value.name}`
    }
  } catch (error) {
    console.error('Error fetching friend info:', error)
  }
}

// 获取消息历史
const messagesInit = async () => {
  const friendId = route.params.friend
  const token = getCookie('chatnext_token'); // 从 Cookie 中读取 token
  if (!token) return

  try {
    const response = await fetch(`/api/messages/get?friend=${friendId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    if (response.ok) {
      const messagesData = await response.json()
      if (messagesData.length > 0) {

        messagesHistory.value = true

        messages.value = messagesData.map(message => {
          // 如果content是字符串形式的JSON，尝试解析
          if (typeof message.content === 'string') {
            try {
              // 检查是否可以解析为JSON
              const parsedContent = JSON.parse(message.content)
              message.content = parsedContent
            } catch (error) {
              // 如果解析失败，保持原样
              console.log('Parse error:', error)
            }
          }

          return message
        }).reverse()

      }
      // 使用 nextTick 确保在 DOM 更新后再滚动
      await nextTick()
      // 添加一个小延时确保渲染完成
      setTimeout(() => {
        scrollToBottom()
      }, 100)
    }
  } catch (error) {
    console.error('Error refreshing messages:', error)
  }
}

// 发送消息
const handleKeydown = (event) => {
  // 如果是Enter但不是和Shift一起按的
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault();
    sendMessage();
  }
}

// AI模型交互
const sendMessage = async () => {

  const token = getCookie('chatnext_token');
  if (!token) {
    openLoginModal()
    return
  }

  record({
    name: 'message_send',
    attributes: {
      // method: 'anonymous',
    },
  });

  const message = newMessage.value.trim()

  if (message === '' && !selectedImage.value) return
  if (thinking.value) return

  messages.value.push({
    role: 'user',
    content: [{
      type: 'text',
      text: message
    }]
  })

  autoResize()
  scrollToBottom()

  thinking.value = true

  // 定义需要重置历史的角色类型
  const resetRoles = ['error', 'clear']
  // 找到最后一条需要重置的消息的索引
  const lastResetIndex = [...messages.value].reverse().findIndex(msg => resetRoles.includes(msg.role))
  // 准备发送的消息数组
  const messagesToSend = lastResetIndex >= 0
    ? messages.value.slice(-(lastResetIndex + 1))
    : messages.value

  const requestBody = JSON.stringify({
    friendId: friendInfo.value.friend_id,
    // userId: localStorage.getItem('user_id'),
    newMessage: newMessage.value,
    messages: messagesToSend
      .filter(msg => msg.role !== 'think') //兼容旧版本
      .filter(msg => msg.role !== 'event')
      .filter(msg => msg.role !== 'clear')
      .filter(msg => msg.role !== 'login')
      .filter(msg => msg.role !== 'capitalist')
      .filter(msg => msg.role !== 'error')
      .slice(-20)
  })

  newMessage.value = ''

  try {
    const response = await fetch('/api/chat', {
      // const response = await fetch('/api/chat/aws', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })

    if (!response.body) throw new Error('ReadableStream not available')

    const reader = response.body.getReader()
    const decoder = new TextDecoder('utf-8')
    let buffer = ''

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read()
      if (done) break

      buffer += decoder.decode(value, { stream: true })
      const chunks = buffer.split('\n\n')

      for (const chunk of chunks.slice(0, -1)) {

        const jsonData = JSON.parse(chunk.replace('data: ', '').trim())

        console.log(jsonData)

        const { event, data } = jsonData

        switch (event) {
          case 'message_start':
            messages.value.push({
              role: 'assistant',
              content: [{
                type: 'text',
                text: '',
              }]
            })
            break

          case 'message_delta':
            // eslint-disable-next-line no-case-declarations
            const textBlock = messages.value[messages.value.length - 1]?.content.find(
              block => block.type === 'text'
            )
            if (textBlock) {
              textBlock.text += data.text
            }
            break

          case 'event':
            messages.value.push({
              role: 'event',
              content: data.content
            })
            break

          case 'login':
            openLoginModal()
            break

          case 'charge':
            messages.value.push({
              role: 'capitalist',
              content: [{
                type: 'charge',
                text: t('chat.seek_profit')
              }]
            })

            record({
              name: 'message_limit',
              attributes: {
              },
            });

            break

          case 'error':
            messages.value.push({
              role: 'error',
              content: [{
                type: 'text',
                text: data.content
              }]
            })
            break

          case 'stop':
            thinking.value = false
            break

        }
        scrollToBottom()
      }

      buffer = chunks[chunks.length - 1]
    }
  } catch (error) {
    console.error('Error in aiModel:', error)
    thinking.value = false
  }
}

// 清除聊天记录
const newChat = () => {
  if (messages.value[messages.value.length - 1]?.role === 'clear') {
    return
  }
  messagesHistory.value = false
  messages.value = []
  messages.value.push({
    role: 'clear',
    content: [{
      type: 'text',
      text: t('chat.new_conversation')
    }]
  })
  scrollToBottom()
}

// 检查消息是否有文本内容
const hasTextContent = (content) => {
  return content.some(block => block.type === 'text')
}

// 检查消息是否有内容
const hasContent = (content) => {
  return content && content.length > 0
}

// 处理滚动事件
const handleScroll = (e) => {
  isScrolled.value = e.target.scrollTop > 200
}

// Markdown 渲染
const renderMarkdown = (content) => {
  return marked.parse(content)
}

// 自动调整输入框高度
const autoResize = () => {
  nextTick(() => {
    const textarea = messageInput.value
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  })
}

// 滚动到底部
const scrollToBottom = () => {
  nextTick(() => {
    const container = mainContainer.value
    container.scrollTop = container.scrollHeight
  })
}

// 路由跳转
const goBack = () => {
  router.push('/')
}

// 跳转到用户信息页面
const goToInfo = (friendId) => {
  const token = getCookie('chatnext_token');
  if (!token) {
    openLoginModal()
    return
  }
  router.push({
    name: 'Info',
    params: { friend: friendId }
  });
};

// 生命周期钩子
onMounted(() => {
  init()
  mainContainer.value.addEventListener('scroll', handleScroll)
})
</script>

<style scoped>
/* 可以根据需要添加样式 */
</style>
