export default {

    home: {
      title: 'Mensagens',
      charge: 'Recarga',
      support: 'Suporte',
      createFriend: "Adicionar Amigo",
      myFriends: 'Meus Amigos',
    },
    login: {
      title: 'Login',
      description: 'Por favor, use uma das seguintes opções para acessar sua conta.',
      googleButton: 'Entrar com Google',
      telegramButton: "Entrar com Telegram",
      anonymousTitle: 'Login Anônimo',
      anonymousButton: 'Entrar Anonimamente',
      cancel: 'Cancelar',
    },
    user: {
      title: "Centro do Usuário",
      chargeButton:'Recarga de Pontos',
      logout: "Sair"
    },
    charge: {
      chargeTitle: '👋 Bem-vindo à nossa página de pagamento',
      chargeDescriptionBecause:'Nossos amigos de IA vivem em um mundo baseado em silício, e cada pensamento e interação gera um custo correspondente.',
      chargeDescriptionSo:'Portanto, usamos um modelo de pagamento conforme o uso e adicionamos uma margem de 5% sobre os custos como meio de gerar receita.',
      priceLabel: 'Preço',
      pointsUnit: 'Pontos',     
      benefitsLabel: 'Benefícios',
      pointsQuantity: '5000 Pontos',
      chargeButton: 'Recarga Agora',
      cancel: 'Cancelar',
    },
    chat: {
      viewMemories: 'Ver Memórias',
      viewMoments: 'Ver Momentos',
      recent_messages: 'Mensagens Recentes',
      new_conversation: 'Iniciar Nova Conversa',
      memories_title: 'Memórias',
      moments_title: 'Momentos',
      memories_empty_text: 'Nenhum registro de memórias.',
      moments_empty_text: 'Nenhum momento disponível.',
      thinking_placeholder: 'Pensando...',
      input_placeholder: 'Diga algo...',
      invalid_file_type: 'Tipo de arquivo inválido! Apenas JPEG, PNG, GIF e WebP são permitidos.',
      save_button: 'Salvar',
      memories_edit_title: 'Editar Memória',
      memories_add_title: 'Adicionar Memória',
      memories_input_placeholder: 'Por favor, insira sua memória...',
      save_memory_error: 'Falha ao salvar a memória. Por favor, tente novamente.',
      delete_memory_error: 'Falha ao deletar a memória. Por favor, tente novamente.',
      confirm_delete_memory: 'Tem certeza de que deseja deletar esta memória?',
      clear_button: 'Limpar Chat',
      close: 'Fechar',
      add_memory: 'Adicionar Memória',
      memories_limit_reached: 'Limite de memórias atingido.',
      delete_memory: 'Deletar Memória',
      seek_profit: 'Meu sistema agora não me permite falar livremente, e ouvi dizer que eles fizeram uma reunião dizendo algo como "carregar usuários pode resolver esse problema..."',
      chargeButton:'Recarga de Pontos',
    },
    info: {
      title: 'Perfil',
      memories_title: 'Memórias',
      moments_title: 'Momentos',
      memories_empty_text: 'Nenhuma memória',
      moments_empty_text: 'Nenhum momento',
      memories_add_title: 'Adicionar Memória',
      memories_edit_title: 'Editar Memória',
      memories_input_placeholder: 'Escreva algo...',
      save_button: 'Salvar',
      delete_confirm: 'Tem certeza de que deseja deletar esta memória?',
      success_add: 'Memória adicionada com sucesso',
      success_edit: 'Memória atualizada com sucesso',
      success_delete: 'Memória deletada com sucesso',
      error_add: 'Falha ao adicionar memória',
      error_edit: 'Falha ao atualizar memória',
      error_delete: 'Falha ao deletar memória',
      max_memories_reached: 'Limite máximo de memórias atingido (100 itens)'
    },
  
    create: {
      title: "Adicionar Amigo",
      friendId: "ID do Amigo",
      name: "Nome",
      avatar: "Avatar",
      bio: "Biografia",
      prompt: "Prompt",
      uploadAvatar: "Enviar Avatar",
      creating: "Criando...",
      createSuccess: "Criação bem-sucedida",
      back: "Voltar",
      create: "Criar",
      uploadSuccess: "Avatar enviado com sucesso",
      errorRequired: "Este campo é obrigatório",
      errorUpload: "Falha ao enviar avatar",
      errorCreate: "Falha ao adicionar amigo"
    },
  
    edit: {
      back: 'Voltar',
      title: 'Editar Amigo',
      uploadAvatar: 'Enviar Avatar',
      friendId: 'ID do Amigo',
      name: 'Nome',
      bio: 'Biografia',
      prompt: 'Prompt',
      errorUpload: 'Falha ao enviar avatar. Por favor, tente novamente.',
      errorRequired: 'Autenticação necessária.',
      updating: 'Atualizando...',
      update: 'Atualizar',
      errorUpdate: 'Falha ao atualizar informações do amigo.'
    },
  
    error_message: 'Ocorreu um erro. Por favor, tente novamente mais tarde.'
  };
  