export default {

  home: {
    title: 'メッセージ',
    charge: 'チャージ',
    support: 'サポート',
    createFriend: "友達を追加",
    myFriends: '私の友達',
  },
  login: {
    title: 'ログイン',
    description: '以下の方法を使用してアカウントにログインしてください。',
    googleButton: 'Googleでログイン',
    telegramButton: "Telegramでログイン",
    anonymousTitle: '匿名ログイン',
    anonymousButton: '匿名でログイン',
    cancel: 'キャンセル',
  },
  user: {
    title: "個人センター",
    chargeButton:'ポイントをチャージ',
    logout: "ログアウト"
  },
  charge: {
    chargeTitle: '👋 私たちの支払いページへようこそ',
    chargeDescriptionBecause:'私たちのAI友達はシリコンベースの世界で生活しており、すべての思考と対話には対応するコストが発生します。',
    chargeDescriptionSo:'そのため、従量課金モデルを採用し、コストに5％のマークアップを加えて収益を上げています。',
    priceLabel: '価格',
    pointsUnit: 'ポイント',     
    benefitsLabel: '特典',
    pointsQuantity: '5000 ポイント',
    chargeButton: '今すぐチャージ',
    cancel: 'キャンセル',
  },
  chat: {
    viewMemories: 'メモリーを表示',
    viewMoments: 'モーメントを表示',
    recent_messages: '最近のメッセージ',
    new_conversation: '新しい会話を始める',
    memories_title: 'メモリー',
    moments_title: 'モーメント',
    memories_empty_text: 'メモリーの記録がありません。',
    moments_empty_text: 'モーメントがありません。',
    thinking_placeholder: '考え中...',
    input_placeholder: '何か話してください...',
    invalid_file_type: '無効なファイルタイプです！JPEG, PNG, GIF, および WebPのみ許可されています。',
    save_button: '保存',
    memories_edit_title: 'メモリーを編集',
    memories_add_title: 'メモリーを追加',
    memories_input_placeholder: 'メモリーを入力してください...',
    save_memory_error: 'メモリーの保存に失敗しました。もう一度お試しください。',
    delete_memory_error: 'メモリーの削除に失敗しました。もう一度お試しください。',
    confirm_delete_memory: 'このメモリーを削除してもよろしいですか？',
    clear_button: 'チャットをクリア',
    close: '閉じる',
    add_memory: 'メモリーを追加',
    memories_limit_reached: 'メモリーの制限に達しました。',
    delete_memory: 'メモリーを削除',
    seek_profit: '私のシステムは今、自由に話すことを許可しておらず、「ユーザーがお金を支払うことでこの問題が解決できる」と会議で言っているのを聞きました...',
    chargeButton:'ポイントをチャージ',
  },
  info: {
    title: 'プロフィール',
    memories_title: 'メモリー',
    moments_title: 'モーメント',
    memories_empty_text: 'メモリーがありません',
    moments_empty_text: 'モーメントがありません',
    memories_add_title: 'メモリーを追加',
    memories_edit_title: 'メモリーを編集',
    memories_input_placeholder: '何かを書いてください...',
    save_button: '保存',
    delete_confirm: 'このメモリーを削除してもよろしいですか？',
    success_add: 'メモリーの追加に成功しました',
    success_edit: 'メモリーの更新に成功しました',
    success_delete: 'メモリーの削除に成功しました',
    error_add: 'メモリーの追加に失敗しました',
    error_edit: 'メモリーの更新に失敗しました',
    error_delete: 'メモリーの削除に失敗しました',
    max_memories_reached: '最大メモリー数（100件）に達しました'
  },

  create: {
    title: "友達を追加",
    friendId: "友達ID",
    name: "名前",
    avatar: "アバター",
    bio: "バイオ",
    prompt: "プロンプト",
    uploadAvatar: "アバターをアップロード",
    creating: "作成中...",
    createSuccess: "作成に成功しました",
    back: "戻る",
    create: "作成",
    uploadSuccess: "アバターのアップロードに成功しました",
    errorRequired: "この項目は必須です",
    errorUpload: "アバターのアップロードに失敗しました",
    errorCreate: "友達の追加に失敗しました"
  },

  edit: {
    back: '戻る',
    title: '友達を編集',
    uploadAvatar: 'アバターをアップロード',
    friendId: '友達ID',
    name: '名前',
    bio: 'バイオ',
    prompt: 'プロンプト',
    errorUpload: 'アバターのアップロードに失敗しました。もう一度お試しください。',
    errorRequired: '認証が必要です。',
    updating: '更新中...',
    update: '更新',
    errorUpdate: '友達情報の更新に失敗しました。'
  },

  error_message: 'エラーが発生しました。後でもう一度お試しください。'
};
