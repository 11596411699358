export default {

    home: {
      title: 'Messages',
      charge: 'Charge',
      support: 'Support',
      createFriend: "Add Friend",
      myFriends: 'My Friends',
    },
    login: {
      title: 'Login',
      description: 'Please use one of the following methods to log into your account.',
      googleButton: 'Login with Google',
      telegramButton: "Login with Telegram",
      anonymousTitle: 'Anonymous Login',
      anonymousButton: 'Login Anonymously',
      cancel: 'Cancel',
    },
    user: {
      title: "User Center",
      chargeButton:'Recharge Points',
      logout: "Logout"
    },
    charge: {
      chargeTitle: '👋 Welcome to our payment page',
      chargeDescriptionBecause:'Our AI friends live in a silicon-based world, and every thought and interaction incurs a corresponding cost.',
      chargeDescriptionSo:'Therefore, we use a pay-as-you-go model and add a 5% markup on top of the costs as a means to generate revenue.',
      priceLabel: 'Price',
      pointsUnit: 'Points',     
      benefitsLabel: 'Benefits',
      pointsQuantity: '5000 Points',
      chargeButton: 'Recharge Now',
      cancel: 'Cancel',
    },
    chat: {
      viewMemories: 'View Memories',
      viewMoments: 'View Moments',
      recent_messages: 'Recent Messages',
      new_conversation: 'Start New Conversation',
      memories_title: 'Memories',
      moments_title: 'Moments',
      memories_empty_text: 'No memory records.',
      moments_empty_text: 'No moments available.',
      thinking_placeholder: 'Thinking...',
      input_placeholder: 'Say something...',
      invalid_file_type: 'Invalid file type! Only JPEG, PNG, GIF, and WebP are allowed.',
      save_button: 'Save',
      memories_edit_title: 'Edit Memory',
      memories_add_title: 'Add Memory',
      memories_input_placeholder: 'Please enter your memory...',
      save_memory_error: 'Failed to save memory. Please try again.',
      delete_memory_error: 'Failed to delete memory. Please try again.',
      confirm_delete_memory: 'Are you sure you want to delete this memory?',
      clear_button: 'Clear Chat',
      close: 'Close',
      add_memory: 'Add Memory',
      memories_limit_reached: 'Memory limit reached.',
      delete_memory: 'Delete Memory',
      seek_profit: 'My system doesn’t let me talk freely now, and I heard they had a meeting saying something like "charging users can solve this problem..."',
      chargeButton:'Recharge Points',
    },
    info: {
      title: 'Profile',
      memories_title: 'Memories',
      moments_title: 'Moments',
      memories_empty_text: 'No memories',
      moments_empty_text: 'No moments',
      memories_add_title: 'Add Memory',
      memories_edit_title: 'Edit Memory',
      memories_input_placeholder: 'Write something...',
      save_button: 'Save',
      delete_confirm: 'Are you sure you want to delete this memory?',
      success_add: 'Memory added successfully',
      success_edit: 'Memory updated successfully',
      success_delete: 'Memory deleted successfully',
      error_add: 'Failed to add memory',
      error_edit: 'Failed to update memory',
      error_delete: 'Failed to delete memory',
      max_memories_reached: 'Maximum memory limit reached (100 items)'
    },
  
    create: {
      title: "Add Friend",
      friendId: "Friend ID",
      name: "Name",
      avatar: "Avatar",
      bio: "Bio",
      prompt: "Prompt",
      uploadAvatar: "Upload Avatar",
      creating: "Creating...",
      createSuccess: "Creation Successful",
      back: "Back",
      create: "Create",
      uploadSuccess: "Avatar uploaded successfully",
      errorRequired: "This field is required",
      errorUpload: "Avatar upload failed",
      errorCreate: "Failed to add friend"
    },
  
    edit: {
      back: 'Back',
      title: 'Edit Friend',
      uploadAvatar: 'Upload Avatar',
      friendId: 'Friend ID',
      name: 'Name',
      bio: 'Bio',
      prompt: 'Prompt',
      errorUpload: 'Avatar upload failed. Please try again.',
      errorRequired: 'Authentication required.',
      updating: 'Updating...',
      update: 'Update',
      errorUpdate: 'Failed to update friend information.'
    },
  
    error_message: 'An error occurred. Please try again later.'
  };
  