<!-- src/components/ChargeModal.vue -->
<template>
    <div v-if="isOpen" class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div class="rounded-lg shadow-2xl p-6 max-w-md w-full backdrop-blur-sm bg-white relative">
            <!-- 关闭按钮 -->
            <button @click="handleClose" class="absolute top-2 right-2 text-gray-600 hover:text-gray-800">
                <x-icon name="x-mark" class="h-6 w-6" />
            </button>

            <!-- 标题和描述 -->
            <h2 class="text-lg font-semibold text-gray-800 mb-4 text-center">
                {{ t('charge.chargeTitle') }}
            </h2>
            <p class="text-gray-600 mb-2 text-[15px]">
                {{ t('charge.chargeDescriptionBecause') }}
            </p>

            <p class="text-gray-600 mb-6 text-[15px]">
                {{ t('charge.chargeDescriptionSo') }}
            </p>
            <!-- 价格和积分信息 -->
            <div class="mb-6">
                <h3 class="text-md font-medium text-gray-800 mb-2">
                    {{ t('charge.priceLabel') }}
                </h3>
                <b class="text-xl bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600">
                    $5.00
                </b>
                <h3 class="text-md font-medium text-gray-800 mt-4 mb-2">
                    {{ t('charge.benefitsLabel') }}
                </h3>
                <b class="text-lg text-gray-700">
                    {{ t('charge.pointsQuantity') }}
                </b>
            </div>

            <!-- 充值按钮 -->
            <button @click="handleCharge"
                class="bg-gradient-to-r from-gray-800 to-black px-4 py-2 rounded-lg shadow-xl w-full mb-3 flex items-center justify-center">
                <div
                    class="flex bg-clip-text text-transparent bg-gradient-to-r from-orange-300 to-purple-300 font-bold">
                    <ArrowPathIcon v-if="isLoading" class="h-6 w-6 text-gray-400 animate-spin mr-2" />
                    {{ t('charge.chargeButton') }}
                </div>
            </button>

            <!-- 取消按钮 -->
            <button @click="handleCancel"
                class="text-gray-500 hover:text-gray-700 text-sm w-full flex items-center justify-center transition-colors">
                {{ t('charge.cancel') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { region } from '../hooks/region';
import { loadStripe } from '@stripe/stripe-js';
import { ArrowPathIcon } from '@heroicons/vue/24/solid';
import { getCookie, record } from '../utils';

// 接收父组件传入的属性
defineProps({
    isOpen: {
        type: Boolean,
        required: true,
    },
});

// 用于触发父组件事件
const emit = defineEmits(['close', 'chargeSuccess']);

// i18n
const { t } = region();

// 控制是否显示加载动画
const isLoading = ref(false);

// 点击关闭按钮
const handleClose = () => {
    emit('close');
};

// 点击取消按钮
const handleCancel = () => {
    emit('close');
};

// 点击充值按钮
const handleCharge = async () => {
    try {
        // 记录埋点事件
        record({
            name: 'rumeng_charge_click',
            attributes: {},
        });

        isLoading.value = true; // 开始加载动画

        // 从 Cookie 中读取 token
        const token = getCookie('chatnext_token');
        if (!token) {
            console.log('No token found');
            isLoading.value = false;
            return;
        }

        // 发起创建支付会话
        const response = await fetch('/api/payment/stripe', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const session = await response.json();

            // 加载 Stripe，并跳转到结算页面
            const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);
            const { error } = await stripe.redirectToCheckout({ sessionId: session.sessionId });

            if (error) {
                console.error('Stripe error:', error);
            } else {
                // 如果跳转成功，可在此触发回调事件
                emit('chargeSuccess');
            }
        } else {
            console.error('Failed to fetch payment session');
        }
    } catch (error) {
        console.error('Payment initiation failed:', error);
    } finally {
        isLoading.value = false; // 结束加载动画
    }
};
</script>

<style scoped>
/* 根据需要添加自定义样式 */
</style>