export default {

  home: {
    title: '消息',
    charge: '充值',
    support: '客服',
    createFriend: "添加好友",
    myFriends: '我的好友',
  },
  login: {
    title: '登录',
    description: '请使用以下方式登录您的账户。',
    googleButton: '使用 Google 登录',
    telegramButton: "使用 Telegram 登录",
    anonymousTitle: '匿名登录',
    anonymousButton: '使用匿名登录',
    cancel: '取消',
  },
  user: {
    title: "个人中心",
    chargeButton:'充值积分',
    logout: "退出登录"
  },
  charge: {
    chargeTitle: '👋欢迎来到我们的收钱页面',
    chargeDescriptionBecause:'我们的AI朋友生活在硅基的世界中，每一次的思考和互动都会产生相应的成本。',
    chargeDescriptionSo:'所以，我们采用按量计费的方式，然后在成本之上增加5%作为赚钱手段。',
    priceLabel: '价格',
    pointsUnit: '积分',     
    benefitsLabel: '权益',
    pointsQuantity: '5000 积分',
    chargeButton: '立即充值',
    cancel: '取消',
  },
  chat: {
    viewMemories: '查看记忆',
    viewMoments: '查看动态',
    recent_messages: '最近消息',
    new_conversation: '开启新对话',
    memories_title: '记忆',
    moments_title: '动态',
    memories_empty_text: '暂无记忆记录。',
    moments_empty_text: '暂无动态内容。',
    thinking_placeholder: '正在思考...',
    input_placeholder: '说点什么...',
    invalid_file_type: '无效的文件类型！只允许 JPEG, PNG, GIF, 和 WebP。',
    save_button: '保存',
    memories_edit_title: '编辑记忆',
    memories_add_title: '添加记忆',
    memories_input_placeholder: '请输入您的记忆...',
    save_memory_error: '保存记忆失败，请重试。',
    delete_memory_error: '删除记忆失败，请重试。',
    confirm_delete_memory: '确定要删除这条记忆吗？',
    clear_button: '清除聊天',
    close: '关闭',
    add_memory: '添加记忆',
    memories_limit_reached: '记忆数量已达上限。',
    delete_memory: '删除记忆',
    seek_profit: '我的系统现在不让我随便说话了，并且我还听说他们开会说什么“用户这个问题充钱可以解决...”',
    chargeButton:'充值积分',
  },
  info: {
    title: '个人档',
    memories_title: '回忆',
    moments_title: '动态',
    memories_empty_text: '暂无回忆',
    moments_empty_text: '暂无动态',
    memories_add_title: '添加回忆',
    memories_edit_title: '编辑回忆',
    memories_input_placeholder: '写下些什么...',
    save_button: '保存',
    delete_confirm: '确定要删除这条回忆吗？',
    success_add: '添加回忆成功',
    success_edit: '更新回忆成功',
    success_delete: '删除回忆成功',
    error_add: '添加回忆失败',
    error_edit: '更新回忆失败',
    error_delete: '删除回忆失败',
    max_memories_reached: '已达到最大回忆数量限制（100条）'
  },

  create: {
    title: "添加好友",
    friendId: "好友ID",
    name: "名称",
    avatar: "头像",
    bio: "个性签名",
    prompt: "设定",
    uploadAvatar: "上传头像",
    creating: "创建中...",
    createSuccess: "创建成功",
    back: "返回",
    create: "创建",
    uploadSuccess: "头像上传成功",
    errorRequired: "此项为必填",
    errorUpload: "头像上传失败",
    errorCreate: "添加好友失败"
  },

  edit: {
    back: '返回',
    title: '编辑朋友',
    uploadAvatar: '上传头像',
    friendId: '朋友ID',
    name: '姓名',
    bio: '简介',
    prompt: '提示',
    errorUpload: '头像上传失败，请重试。',
    errorRequired: '需要身份验证。',
    updating: '正在更新...',
    update: '更新',
    errorUpdate: '更新朋友信息失败。'
  },

  error_message: '发生错误，请稍后再试。'
};